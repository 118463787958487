<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">CREATE A NEW ITEM</p>
    </header>
    <section class="modal-card-body">
      <b-field grouped>
        <b-field label="Name">
          <b-input
            v-model="form.name"
            placeholder="Oscar"
            required
            expanded>
          </b-input>
        </b-field>
        <b-field label="Cost" expanded>
          <b-numberinput
            v-model="form.cost"
            placeholder="5000"
            step="1"
            min="0"
            block controls-position="compact"
            controls-alignment="right" />
        </b-field>
        <b-field label="Hidden">
          <b-switch class="mt-2 ml-1" v-model="form.isHidden" type="is-info" />
        </b-field>
      </b-field>
      <b-field label="Description">
        <div class="control">
            <textarea v-model="form.description" class="textarea has-fixed-size" placeholder="Allows you to hold an Oscar!" required="required"></textarea>
        </div>
      </b-field>
      <b-field grouped>
        <b-field label="Body Part">
          <b-select required v-model="form.type" @input="fillBlacklists">
            <option
              v-for="part in types"
              v-bind:key="part"
              :value="part">
              {{ part }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Part Blacklists">
          <multiselect
            v-model="form.blacklists"
            :options="types"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick blacklists"
            :preselect-first="true">
            <template #selection="{values, isOpen}">
              <span
                class="multiselect__single"
                v-if="values.length && !isOpen">
                {{ values.length }} blacklists selected
              </span>
              </template>
          </multiselect>
        </b-field>

        <b-field label="Image" :class="{'has-name': !!form.file}">
          <b-upload v-model="form.file" class="file-label file is-primary">
          <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Click to upload</span>
          </span>
          <span class="file-name" v-if="form.file">
              {{ form.file.name }}
          </span>
          </b-upload>
        </b-field>
      </b-field>

      <div class="columns mt-5">
        <div class="column is-4 is-offset-8 buttons">
          <b-button
            label="Cancel"
            type="is-primary"
            @click="$emit('close')" />
          <b-button
            label="Submit"
            type="is-info"
            @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.multiselect__content {
  z-index: 9999999999;
}
</style>

<script>
import { createItem } from '@/api/items'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  data: () => ({
    form: {
      name: null,
      description: null,
      cost: null,
      type: 'head',
      blacklists: [],
      isHidden: false,
      file: null
    }
  }),
  props: {
    types: Array,
    blacklists: Object
  },
  methods: {
    fillBlacklists () {
      this.form.blacklists = this.blacklists[this.form.type]
    },
    async submit () {
      const { name, cost, description, type, file, isHidden, blacklists } = this.form
      const data = new FormData()
      data.append('name', name)
      data.append('cost', cost)
      data.append('description', description)
      data.append('type', type)
      data.append('blacklists', blacklists)
      data.append('isHidden', isHidden)
      data.append('image', file)

      try {
        await createItem(data)
        this.$buefy.notification.open({
          message: `Item <b>${name}</b> created successfully!<br/>Refresh to view changes.`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit', this.form)
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/sass/darkmode.scss";

.multiselect .multiselect__tags,
.multiselect .multiselect__tags span,
.multiselect .multiselect__tags input {
  background-color: $ddarker;
  border-color: $ddarker;
  color: $text;
}

.multiselect .multiselect__content-wrapper {
  background-color: $ddark;
  border-color: $ddark;
  color: $text
}

.multiselect .multiselect__option--selected,
.multiselect .multiselect__option--highlight,
.multiselect .multiselect__option--highlight::after {
  background-color: $dblue;
  border-block: $dblue;
  color: $text;
}

</style>
