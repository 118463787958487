import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=923890c8&scoped=true&"
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"
import style0 from "./Create.vue?vue&type=style&index=0&id=923890c8&prod&lang=scss&scoped=true&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&"
import style2 from "./Create.vue?vue&type=style&index=2&id=923890c8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "923890c8",
  null
  
)

export default component.exports