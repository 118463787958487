<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">MODEL PREVIEW</p>
    </header>
    <section class="modal-card-body">
        <div>{{itemData}}</div>

      <div class="columns mt-5">
        <div class="column is-4 is-offset-8 buttons">
            <b-button
                label="Cancel"
                @click="$emit('close')" />
            <b-button
                label="Submit"
                type="is-info"
                @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  props: {
    itemData: Object,
    modelItems: Array
  },
  methods: {
    async submit () {
    }
  }
}
</script>
