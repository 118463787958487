<template>
    <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">EDIT '{{ itemData.name }}'</p>
    </header>
    <section class="modal-card-body">
      <b-field grouped>
        <b-field label="Name">
            <b-input
            v-model="form.name"
            placeholder="Oscar"
            required
            expanded>
            </b-input>
        </b-field>
        <b-field label="Cost" expanded>
            <b-numberinput v-model="form.cost" placeholder="5000" step="1" min="1" block controls-position="compact" controls-alignment="right"></b-numberinput>
        </b-field>
        <b-field label="Hidden">
          <b-switch class="mt-2 ml-1" v-model="form.isHidden" type="is-info" />
        </b-field>
      </b-field>
      <b-field label="Description">
        <div class="control">
            <textarea v-model="form.description" class="textarea has-fixed-size" placeholder="Allows you to hold an Oscar!" required="required"></textarea>
        </div>
      </b-field>
      <b-field grouped>
        <b-field label="Body Part">
          <b-select required v-model="form.type">
            <option
              v-for="part in types"
              v-bind:key="part"
              :value="part">
              {{ part }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Part Blacklists">
          <multiselect
            v-model="form.blacklists"
            :options="types"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick blacklists"
            :preselect-first="true">
            <template #selection="{values, isOpen}">
              <span
                class="multiselect__single"
                v-if="values.length && !isOpen">
                {{ values.length }} blacklists selected
              </span>
              </template>
          </multiselect>
        </b-field>

        <b-field label="Image" :class="{'has-name': !!form.file}">
          <b-upload v-model="form.file" class="file-label file is-primary">
          <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Click to upload</span>
          </span>
          <span class="file-name" v-if="form.file">
              {{ form.file.name }}
          </span>
          </b-upload>
        </b-field>
      </b-field>

      <div class="columns mt-5">
        <div class="column is-8 buttons">
            <b-button
              label="Model"
              type="is-primary"
              @click="openModelModal" />
            <b-button
              v-if="hasPermission('users.update')"
              label="Bulk Add"
              type="is-primary"
              @click="openBulkAddModal" />
        </div>
        <div class="column is-4 buttons">
            <b-button
              label="Cancel"
              type="is-primary"
              @click="$emit('close')" />
            <b-button
              label="Submit"
              type="is-info"
              @click="submit" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { updateItem } from '@/api/items'
import { hasPermission } from '@/utils/permissions'
import ModelModal from './modals/Model'
import BulkAddModal from './modals/BulkAdd.vue'

export default {
  components: { Multiselect },
  props: {
    itemData: Object,
    types: Array,
    blacklists: Object
  },
  data: () => ({
    form: {
      name: null,
      description: null,
      cost: null,
      type: 'head',
      blacklists: [],
      isHidden: false,
      image: null
    }
  }),
  methods: {
    fillBlacklists () {
      this.form.blacklists = this.blacklists[this.form.type]
    },
    hasPermission (permission) {
      return hasPermission(permission)
    },
    openModelModal () {
      this.$buefy.modal.open({
        parent: this,
        component: ModelModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          itemData: this.itemData
        }
      })
    },
    openBulkAddModal () {
      this.$buefy.modal.open({
        parent: this,
        component: BulkAddModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          itemData: this.itemData
        }
      })
    },
    async submit () {
      const { name, cost, description, file, type, isHidden, blacklists } = this.form
      const data = new FormData()
      if (name !== this.itemData.name) data.append('name', name)
      if (cost !== this.itemData.cost) data.append('cost', cost)
      if (description !== this.itemData.description) data.append('description', description)
      if (type !== this.itemData.type) data.append('type', type)
      if (isHidden !== this.itemData.isHidden) data.append('isHidden', isHidden)
      if (blacklists !== this.itemData.blacklists) data.append('blacklists', blacklists)
      if (file) data.append('image', file)

      try {
        await updateItem(this.itemData.id, data)
        this.$buefy.notification.open({
          message: `Item <b>${this.itemData.name}</b> updated successfully!<br/>Refresh to view changes.`,
          type: 'is-success'
        })
        this.$emit('close')
        this.$emit('submit', this.idx, this.form)
      } catch (err) {
        this.$buefy.notification.open({
          message: err.message,
          type: 'is-danger'
        })
      }
    }
  },
  mounted () {
    this.form = { ...this.itemData }
  }
}
</script>
