<template>
    <div>
      <section class="hero is-primary">
        <div class="hero-body">
          <p class="title">
            Items
          </p>
          <p class="subtitle">
            Customize your Clappy's looks
          </p>
        </div>
        <div class="hero-foot">
          <nav class="tabs">
            <div class="container">
              <ul>
                <!-- TODO: search -->
                <li v-if="false">
                  <b-input
                    class="is-primary"
                    placeholder="Search..."
                    type="search"
                    v-model="searchQuery" />
                </li>
                <li v-if="hasPermission('items.create')"><b-button type="is-primary" @click="create">Create Item</b-button></li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      <div class="container mt-3">
        <div class="columns is-vcentered">
          <div class="column is-one-quarter">
            <canvas id="clappyCanvas" width="300" height="300" />
            <p><strong>Balance</strong>: {{ $store.state.user.user.settings.tickets }} tickets</p>
            <p v-if="!it.default && !owned(item)"><small>(New Balance:  <i>{{ $store.state.user.user.settings.tickets - costDiscounted(item.cost) }}</i> tickets)</small></p>

            <div class="buttons" v-if="!it.default">
              <!-- todo already activated? -->
              <b-button type="is-info" v-if="!active(item) && (owned(item) ? true : $store.state.user.user.settings.tickets > costDiscounted(item.cost))" @click="purchase(owned(item))">{{ !owned(item) ? 'Purchase' : 'Activate'}}</b-button>
              <b-button type="is-danger" v-if="active(item)" @click="deactivate">Deactivate</b-button>
              <b-button type="is-danger" v-if="hasPermission('items.update')" @click="edit">Edit</b-button>
              <b-button type="is-danger" v-if="hasPermission('items.delete')" @click="del">Delete</b-button>
            </div>
          </div>
          <div class="column" style="overflow-y:auto;max-height:70vh">
            <div class="part" v-for="part of sortedParts" :key="part">
              <h1>{{ capitalizeFirst(part) }}</h1>
              <div class="columns is-multiline is-mobile" v-if="parts[part] && parts[part].items.length" >
                <div
                  class="column is-4-mobile is-3-tablet"
                  v-for="(itm, i) in computedParts[part].items"
                  :key="i"
                  style="cursor:pointer;"
                  @click="selectItem(part, i)">
                  <div class="card">
                    <div class="card-image">
                      <b-image :src="itm.modelImage" :class="owned(itm) ? (active(itm) ? 'clappy-item-active' : 'on-inventory') : costDiscounted(itm.cost) > $store.state.user.user.settings.tickets ? 'cant-purchase' : 'can-purchase'"/>
                    </div>
                    <div class="card-content">
                      <ul><strong>{{ itm.name }}</strong></ul>
                      <ul>{{ itm.cost === 0 ? 'Free' : `${costDiscounted(itm.cost)} tickets` }}</ul>
                      <ul v-if="itm.isHidden && hasPermission('items.view.hidden')"><strong>Hidden Item</strong></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading @infinite="loadMore" spinner="spiral">
              <template slot="no-more">All items loaded.</template>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "@/sass/variables.scss";
.part
  margin-bottom: 10px;
  border-bottom: 1px solid $primary;

.cant-purchase
  border-bottom: 3px solid $danger !important;

.can-purchase
  border-bottom: 3px solid $warning !important;

.on-inventory
  border-bottom: 3px solid $secondary !important;

.clappy-item-active
  border-bottom: 3px solid $success !important;
</style>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { hasPermission } from '@/utils/permissions'
import { purchaseItem, deactivateItem, getUser } from '@/api/users'
import { getItems, getTypesBlacklists, getModels } from '@/api/items'
import CreateModal from './modals/Create'
import EditModal from './modals/Edit'
import DeleteModal from './modals/Delete'
import { getGuild } from '@/api/guild'
import { getDiscounted } from '@/utils/utils'

export default {
  components: {
    InfiniteLoading
  },
  data: () => ({
    clappyCanvas: {},
    clappyCtx: {},
    parts: {},
    activePart: 0,
    searchQuery: '',
    defaultPartBlacklists: {},
    sortedParts: ['head', 'face', 'hat', 'body', 'jacket', 'hands', 'left hand', 'right hand', 'pants', 'legs', 'left leg', 'right leg', 'full body', 'outfit', 'pet', 'background'],
    types: ['background', 'pet', 'full body', 'legs', 'left leg', 'right leg', 'body', 'head', 'outfit', 'pants', 'jacket', 'face', 'hat', 'hands', 'left hand', 'right hand'],
    image: '',
    it: { default: true },
    modelItems: [],
    guildSettings: {
      premiumStoreOff: 10
    }
  }),
  computed: {
    item () {
      if (!this.parts[this.it.part]) return null
      return this.parts[this.it.part].items[this.it.i]
    },
    computedParts () {
      // TODO: search
      if (this.searchQuery) {
        const nParts = {}
        for (const k of this.parts) {
          const part = this.parts[k].items
          const items = part.filter(i => i.name.toLowerCase().includes(this.searchQuery))
          if (items.length) nParts[k].items = items
        }
        return nParts
      }
      return this.parts
    }
  },
  methods: {
    selectItem (part, i) {
      this.it = { part, i }
      this.makeImage()
    },
    capitalizeFirst (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    fetchItemImage (item) {
      return new Promise((resolve, reject) => {
        const image = new Image()

        function cleanup () {
          image.onload = null
          image.onerror = null
        }

        image.onload = () => { cleanup(); resolve(image) }
        image.onerror = (err) => { cleanup(); reject(err) }

        image.crossOrigin = 'anonymous'
        image.src = `${process.env.VUE_APP_API_URL}/public/${item.name}.png`
      })
    },
    async loadUserItems () {
      for (let i = 0; i < this.$store.state.user.user.settings.items.length; i++) {
        const img = await this.fetchItemImage(this.$store.state.user.user.settings.items[i].item)
        this.$store.state.user.user.settings.items[i].item.loaded = img
      }
    },
    owned (item) {
      return this.$store.state.user.user.settings.items.some(i => i.item.id === item.id)
    },
    active (item) {
      return this.$store.state.user.user.settings.items.some(i => i.item.id === item.id && i.isActive === true)
    },
    async makeImage () {
      // todo: fill with model?
      const item = this.item
      if (item && !item.loaded) {
        const load = this.$buefy.loading.open()
        const img = await this.fetchItemImage(item)
        this.parts[this.it.part].items[this.it.i].loaded = img
        load.close()
      }

      this.clappyCtx.clearRect(0, 0, this.clappyCanvas.width, this.clappyCanvas.height)
      const userItems = this.$store.state.user.user.settings.items.filter(i => i.isActive && (this.it.default ? true : !item.blacklists.includes(i.item.type) && !i.item.blacklists.includes(item.type)))
      const cant = userItems.reduce((acc, i) => [...acc, ...i.item.blacklists], [])
      if (item) cant.push(...item.blacklists)

      for (const type of this.types) {
        if (item && type === item.type) {
          this.clappyCtx.drawImage(item.loaded, 0, 0, this.clappyCanvas.width, this.clappyCanvas.height)
        } else {
          if (cant.includes(type)) continue

          let uitem = userItems.find((i) => i.item.type.toLowerCase() === type)
          if (!uitem) {
            uitem = this.modelItems.find((i) => i.type.toLowerCase() === type)
            if (uitem && !uitem.loaded) uitem.loaded = await this.fetchItemImage(uitem)
          } else uitem = uitem.item

          if (uitem) this.clappyCtx.drawImage(uitem.loaded, 0, 0, this.clappyCanvas.width, this.clappyCanvas.height)
        }
      }
    },
    hasPermission (permission) {
      return hasPermission(permission)
    },
    processParts () {
      for (const p of this.types) { this.$set(this.parts, p, { page: 1, items: [], loaded: false }) }
    },
    async loadMore ($state) {
      const part = this.sortedParts[this.activePart]
      await this.fetchItems(part)

      $state.loaded()
      if (this.parts[part].page === 0) {
        this.activePart++
        if (this.activePart === this.sortedParts.length) {
          $state.complete()
        }
      }
    },
    async fetchModelItems () {
      this.modelItems = await getModels()
    },
    async fetchItems (type) {
      const items = await getItems(this.parts[type].page, type)
      this.parts[type].items.push(...items)

      if (items.length < 50) this.parts[type].page = 0 // all loading done
      else this.parts[type].page++
    },
    async fetchPartBlacklists () {
      this.defaultPartBlacklists = await getTypesBlacklists()
    },
    async deactivate () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to deactivate this item?',
        onConfirm: async () => {
          try {
            await deactivateItem('@me', { id: this.item.id })

            const user = await getUser()
            this.$store.dispatch('user/setUser', user)
            await this.loadUserItems()

            this.makeImage()
            this.$buefy.notification.open({
              message: `Item <b>${this.item.name}</b> deactivated successfully!`,
              type: 'is-success'
            })
          } catch (err) {
            this.$buefy.notification.open({
              message: err,
              type: 'is-danger'
            })
          }
        }
      })
    },
    purchase (owned) {
      const nTickets = this.$store.state.user.user.settings.tickets - this.costDiscounted(this.item.cost)
      this.$buefy.dialog.confirm({
        message: owned ? 'Are you sure you want to activate this item?' : `Are you sure you want to purchase this item for <strong>${this.costDiscounted(this.item.cost)} tickets</strong>?<br/>Your new balance will be <strong>${nTickets} tickets</strong>`,
        onConfirm: async () => {
          try {
            await purchaseItem('@me', { id: this.item.id })

            const user = await getUser()
            this.$store.dispatch('user/setUser', user)
            await this.loadUserItems()

            this.makeImage()
            this.$buefy.notification.open({
              message: `Item <b>${this.item.name}</b> activated successfully!`,
              type: 'is-success'
            })
          } catch (err) {
            this.$buefy.notification.open({
              message: err,
              type: 'is-danger'
            })
          }
        }
      })
    },
    create () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          types: this.types,
          blacklists: this.defaultPartBlacklists
        }
      })
    },
    activate () {
      // TODO activate item
    },
    edit () {
      this.$buefy.modal.open({
        parent: this,
        component: EditModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          itemData: this.item,
          types: this.types
        }
      })
    },
    del () {
      this.$buefy.modal.open({
        parent: this,
        component: DeleteModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          item: this.item
        }
      })
    },
    costDiscounted (cost) {
      return getDiscounted(this.guildSettings.premiumStoreOff, cost, this.$store.state.user.user.settings)
    }
  },
  async beforeMount () {
    this.guildSettings = (await getGuild()).settings
  },
  mounted () {
    var c = document.getElementById('clappyCanvas')
    this.clappyCanvas = c
    var ctx = c.getContext('2d')
    this.clappyCtx = ctx

    this.processParts()
    this.fetchPartBlacklists()
    this.fetchModelItems()
    const load = this.$buefy.loading.open()
    this.loadUserItems().then(() => this.makeImage().then(load.close))
  }
}
</script>
