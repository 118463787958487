export const getDiscounted = (premiumStoreOff, cost, user) => {
  if (!user) return cost

  return isPremium(user)
    ? Math.floor(cost * (1 - (premiumStoreOff / 100)))
    : cost
}

export const isPremium = (user) => {
  if (!user || !user.lastPremium) return false

  const date = new Date(user.lastPremium)
  if (!date) return false

  return date.getTime() < Date.now()
}
