// import store from '@/store'
import request from '@/utils/request'

export const getItems = async (page = 1, type) => {
  return request({
    url: '/items',
    method: 'get',
    params: {
      page, type, limit: 50
    }
  })
}

export const getTypesBlacklists = async () => {
  return request({
    url: '/items/types',
    method: 'get'
  })
}

export const getModels = async () => {
  return request({
    url: '/items/models',
    method: 'get'
  })
}

export const createItem = async (data) => {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/items',
    method: 'post',
    data
  })
}

export const deleteItem = async (id) => {
  return request({
    url: `/items/${id}`,
    method: 'delete'
  })
}

export const updateItem = async (id, data) => {
  return request({
    url: `/items/${id}`,
    method: 'put',
    data
  })
}

export const itemBulkAdd = async (id, data) => {
  return request({
    url: `/items/${id}/bulkadd`,
    method: 'put',
    data
  })
}
